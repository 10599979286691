/* eslint-disable no-unused-vars */
import i18next from "i18next";
import { useEffect, useState } from "react";
import api from "../services/api";
import { defaults } from "../constants/general";

/**
 * This hook returns the correct territorial grouping based on the current territory type.
 * @param {The current territory} territory
 */
export default function useGrouping(
  territory,
  dataType,
  paramsLoaded,
  groupingOptions,
  initiative,
  hasntFirstTerritory
) {
  const [groups, setGroups] = useState(groupingOptions);
  const [selectedGroup, setSelectedGroup] = useState();
  const [hierarchy, setHierarchy] = useState();

  useEffect(() => {
    setSelectedGroup(defaults[dataType].grouping);
  }, [dataType]);

  function reset() {
    let isSubscribed = true;

    if (territory) {
      api
        .get(`territories/${territory.type}/${territory.code}/groupings`, {
          params: { dataType, initiative },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            const groupsAux = {};
            let firstGroup = "";

            if (data[defaults[dataType].grouping]) {
              firstGroup = defaults[dataType].grouping;
            }

            Object.keys(data).forEach((key) => {
              if (firstGroup === "") firstGroup = key;
              groupsAux[key] = {
                translation: `dashboard.filters.grouping.${key}`,
              };

              i18next.addResource(
                "pt",
                "translation",
                `dashboard.filters.grouping.${key}`,
                data[key].pt
              );
              i18next.addResource(
                "en",
                "translation",
                `dashboard.filters.grouping.${key}`,
                data[key].en
              );
              i18next.addResource(
                "es",
                "translation",
                `dashboard.filters.grouping.${key}`,
                data[key].es
              );
            });

            setGroups(groupsAux);

            if (paramsLoaded || hasntFirstTerritory) {
              setSelectedGroup(firstGroup);
            }
          }
        });

      api.get(`territories/translations`).then(({ data }) => {
        if (isSubscribed) {
          const hierarchyAux = [];

          Object.keys(data).forEach((key) => {
            hierarchyAux.push({ position: data[key].hierarchy, type: key });
          });

          setHierarchy(hierarchyAux);
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
  }

  /**
   * Generate groups options to the selected territory.
   */
  useEffect(() => {
    if (territory) {
      reset();
    }
  }, [territory, initiative, dataType]);

  return [groups, hierarchy, selectedGroup, setSelectedGroup, reset];
}
