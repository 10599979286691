/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import { useTheme } from "react-jss";
import React, { useContext, useEffect, useState } from "react";
import bbox from "@turf/bbox";
import { FlyToInterpolator, WebMercatorViewport } from "react-map-gl";
import { easeCubic } from "d3-ease";
import FiltersContext from "../../../contexts/filtering";
import MapContext from "../../../contexts/mapping";
import useTerritoryHistory from "../../../hooks/territoryHistory";
import { isTouchDevice } from "../../../constants/utils";
import CustomPopup from "../../../components/Dashboard/MapView/CustomPopup";
import api from "../../../services/api";
import conflictsData from "../../../assets/json/conflicts.json";

/**
 * Export the map layer.
 */
function Map() {
  const { mapRef, mapLoaded } = useContext(MapContext);
  const {
    options: { territoriesOptions, analysisOptions, initiativeOptions },
    values: {
      analysis,
      timeMode,
      years,
      months,
      territory,
      grouping,
      hierarchy,
      filtersWaterClassification,
      statisticsOpened,
      dataType,
      initiative,
    },
    setters: { setTerritoriesOptions, setTerritory },
  } = useContext(FiltersContext);
  const {
    setOnClick,
    setOnHover,
    baseMap,
    opacity,
    setInfoContent,
    viewport,
    animation,
    setViewport,
    layers,
    transparentBackground,
  } = useContext(MapContext);
  const theme = useTheme();
  const territoryHistory = useTerritoryHistory(
    hierarchy,
    territory,
    grouping,
    dataType,
    initiative,
    initiativeOptions
  );
  const rasterConfig = {
    type: "raster",
    tileSize: 256,
    paint: {
      "raster-resampling": "nearest",
    },
  };
  const [popup, setPopup] = useState();
  const [surfaceUrl, setSurfaceUrl] = useState();
  const [transitionUrl, setTransitionUrl] = useState();
  const [trendsUrl, setTrendsUrl] = useState();
  const [waterClassificationUrls, setWaterClassificationUrls] = useState();
  const [fetchedIntermittentRaster, setFetchedIntermittentRaster] = useState();
  const [fetchedIrrigationRaster, setFetchedIrrigationRaster] = useState();
  const [
    fetchedInititiveTerritoriesRaster,
    setFetchedInititiveTerritoriesRaster,
  ] = useState();

  /**
   * Gets the surface raster url
   */
  useEffect(() => {
    let isSubscribed = true;

    const startYear = years[0];
    let endYear = years[0];

    if (years.length > 1) {
      endYear = years[1];
    }

    let route = `/surface/map/annual/${startYear}/${endYear}`;

    if (timeMode !== "annual" && months) {
      const startMonth = months[0];
      route = `/surface/map/monthly/${startYear}/${startMonth}`;
    }

    if (analysis === analysisOptions.surface?.key) {
      api
        .get(route, {
          params: { dataType, initiative },
        })
        .then(({ data: { url } }) => {
          if (isSubscribed) {
            setSurfaceUrl(url);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [years, months, dataType, analysis, timeMode, initiative]);

  /**
   * Gets the transition raster url
   */
  useEffect(() => {
    let isSubscribed = true;

    const startYear = years[0];
    let endYear = years[0];

    if (years.length > 1) {
      endYear = years[1];
    }

    if (analysis === analysisOptions.transition?.key) {
      api
        .get(`/transitions/map/annual/${startYear}/${endYear}`, {
          params: { dataType, initiative },
        })
        .then(({ data: { url } }) => {
          if (isSubscribed) {
            setTransitionUrl(url);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [years, dataType, analysis, initiative]);

  /**
   * Gets the trends raster url
   */
  useEffect(() => {
    let isSubscribed = true;

    const startYear = years[0];
    let endYear = years[0];

    if (years.length > 1) {
      endYear = years[1];
    }

    if (analysis === analysisOptions.trend?.key) {
      api
        .get(`/trend/map/${startYear}/${endYear}`, { params: { initiative } })
        .then(({ data: { url } }) => {
          if (isSubscribed) {
            setTrendsUrl(url);
          }
        })
        .catch(() => {
          setTrendsUrl();
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [years, analysis, initiative]);

  /**
   * Gets the classification raster url
   */
  useEffect(() => {
    let isSubscribed = true;

    const startYear = years[0];

    if (analysis === analysisOptions.waterClassification?.key) {
      api
        .get(`/classification/map/${startYear}`, { params: { initiative } })
        .then(({ data }) => {
          if (isSubscribed) {
            setWaterClassificationUrls(data);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [years, analysis, initiative]);

  function updateRasterLayer() {
    const map = mapRef.current.getMap();

    if (map.getLayer("raster-layer")) {
      map.removeLayer("raster-layer");
    }
    if (map.getSource("raster")) {
      map.removeSource("raster");
    }

    if (analysis === analysisOptions.surface.key) {
      if (surfaceUrl) {
        map.addSource("raster", {
          ...rasterConfig,
          tiles: [surfaceUrl],
        });
        map.addLayer(
          {
            id: "raster-layer",
            source: "raster",
            type: "raster",
            "tile-size": 256,
            paint: {
              "raster-resampling": "nearest",
            },
          },
          "road-street"
        );
      }
    } else if (analysis === analysisOptions.transition.key) {
      if (transitionUrl) {
        map.addSource("raster", {
          ...rasterConfig,
          tiles: [transitionUrl],
        });
        map.addLayer(
          {
            id: "raster-layer",
            source: "raster",
            type: "raster",
            "tile-size": 256,
            paint: {
              "raster-resampling": "nearest",
            },
          },
          "road-street"
        );
      }
    } else if (analysis === analysisOptions.trend.key) {
      if (timeMode === "annual" && trendsUrl) {
        map.addSource("raster", {
          ...rasterConfig,
          tiles: [trendsUrl],
        });
        map.addLayer(
          {
            id: "raster-layer",
            source: "raster",
            type: "raster",
            "tile-size": 256,
            paint: {
              "raster-resampling": "nearest",
            },
          },
          "road-street"
        );
      }
    }
  }

  /**
   * Handle the map layer visualization.
   */
  useEffect(() => {
    if (mapLoaded) {
      updateRasterLayer();
    }
  }, [
    mapLoaded,
    analysis,
    timeMode,
    years,
    months,
    theme,
    surfaceUrl,
    transitionUrl,
    trendsUrl,
  ]);

  /**
   * Handle opacity change
   */
  useEffect(() => {
    const map = mapRef.current.getMap();

    if (analysis === analysisOptions.waterClassification?.key) {
      if (map.getLayer("raster-layer-natural")) {
        map.setPaintProperty(
          "raster-layer-natural",
          "raster-opacity",
          opacity / 100
        );
      }
      if (map.getLayer("raster-layer-anthropic")) {
        map.setPaintProperty(
          "raster-layer-anthropic",
          "raster-opacity",
          opacity / 100
        );
      }
      if (map.getLayer("raster-layer-hydroelectric")) {
        map.setPaintProperty(
          "raster-layer-hydroelectric",
          "raster-opacity",
          opacity / 100
        );
      }
      if (map.getLayer("raster-layer-mining")) {
        map.setPaintProperty(
          "raster-layer-mining",
          "raster-opacity",
          opacity / 100
        );
      }
    } else if (map.getLayer("raster-layer")) {
      map.setPaintProperty("raster-layer", "raster-opacity", opacity / 100);
    }
  }, [opacity]);

  function updateNaturalLayer() {
    const map = mapRef.current.getMap();

    if (map.getLayer("raster-layer-natural")) {
      map.removeLayer("raster-layer-natural");
    }
    if (map.getSource("raster_natural")) {
      map.removeSource("raster_natural");
    }

    if (
      analysis === analysisOptions.waterClassification?.key &&
      timeMode === "annual" &&
      filtersWaterClassification.natural &&
      waterClassificationUrls
    ) {
      map.addSource("raster_natural", {
        ...rasterConfig,
        tiles: [waterClassificationUrls.natural?.url],
      });
      map.addLayer(
        {
          id: "raster-layer-natural",
          source: "raster_natural",
          type: "raster",
          "tile-size": 256,
          paint: {
            "raster-resampling": "nearest",
            "raster-opacity": opacity / 100,
          },
        },
        "road-street"
      );
    }
  }

  function updateAnthropicLayer() {
    const map = mapRef.current.getMap();
    if (map.getLayer("raster-layer-anthropic")) {
      map.removeLayer("raster-layer-anthropic");
    }
    if (map.getSource("raster_anthropic")) {
      map.removeSource("raster_anthropic");
    }

    if (
      analysis === analysisOptions.waterClassification?.key &&
      timeMode === "annual" &&
      filtersWaterClassification.anthropic &&
      waterClassificationUrls
    ) {
      map.addSource("raster_anthropic", {
        ...rasterConfig,
        tiles: [waterClassificationUrls.anthropic?.url],
      });
      map.addLayer(
        {
          id: "raster-layer-anthropic",
          source: "raster_anthropic",
          type: "raster",
          "tile-size": 256,
          paint: {
            "raster-resampling": "nearest",
            "raster-opacity": opacity / 100,
          },
        },
        "road-street"
      );
    }
  }

  function updateHydroelectricLayer() {
    const map = mapRef.current.getMap();
    if (map.getLayer("raster-layer-hydroelectric")) {
      map.removeLayer("raster-layer-hydroelectric");
    }
    if (map.getSource("raster_hydroelectric")) {
      map.removeSource("raster_hydroelectric");
    }

    if (
      analysis === analysisOptions.waterClassification?.key &&
      timeMode === "annual" &&
      filtersWaterClassification.hydroelectric &&
      waterClassificationUrls
    ) {
      map.addSource("raster_hydroelectric", {
        ...rasterConfig,
        tiles: [waterClassificationUrls.hydroelectric?.url],
      });
      map.addLayer(
        {
          id: "raster-layer-hydroelectric",
          source: "raster_hydroelectric",
          type: "raster",
          "tile-size": 256,
          paint: {
            "raster-resampling": "nearest",
            "raster-opacity": opacity / 100,
          },
        },
        "road-street"
      );
    }
  }

  function updateMiningLayer() {
    const map = mapRef.current.getMap();
    if (map.getLayer("raster-layer-mining")) {
      map.removeLayer("raster-layer-mining");
    }
    if (map.getSource("raster_mining")) {
      map.removeSource("raster_mining");
    }

    if (
      analysis === analysisOptions.waterClassification?.key &&
      timeMode === "annual" &&
      filtersWaterClassification.mining &&
      waterClassificationUrls
    ) {
      map.addSource("raster_mining", {
        ...rasterConfig,
        tiles: [waterClassificationUrls.mining?.url],
      });
      map.addLayer(
        {
          id: "raster-layer-mining",
          source: "raster_mining",
          type: "raster",
          "tile-size": 256,
          paint: {
            "raster-resampling": "nearest",
            "raster-opacity": opacity / 100,
          },
        },
        "road-street"
      );
    }
  }

  function updateAquacultureLayer() {
    const map = mapRef.current.getMap();
    if (map.getLayer("raster-layer-aquaculture")) {
      map.removeLayer("raster-layer-aquaculture");
    }
    if (map.getSource("raster_aquaculture")) {
      map.removeSource("raster_aquaculture");
    }

    if (
      analysis === analysisOptions.waterClassification?.key &&
      timeMode === "annual" &&
      filtersWaterClassification.aquaculture &&
      waterClassificationUrls
    ) {
      map.addSource("raster_aquaculture", {
        ...rasterConfig,
        tiles: [waterClassificationUrls.aquaculture?.url],
      });
      map.addLayer(
        {
          id: "raster-layer-aquaculture",
          source: "raster_aquaculture",
          type: "raster",
          "tile-size": 256,
          paint: {
            "raster-resampling": "nearest",
            "raster-opacity": opacity / 100,
          },
        },
        "road-street"
      );
    }
  }

  useEffect(() => {
    if (mapLoaded) {
      updateNaturalLayer();
    }
  }, [
    mapLoaded,
    filtersWaterClassification.natural,
    analysis,
    timeMode,
    years,
    months,
    theme,
    waterClassificationUrls,
  ]);

  useEffect(() => {
    if (mapLoaded) {
      updateAnthropicLayer();
    }
  }, [
    mapLoaded,
    filtersWaterClassification.anthropic,
    analysis,
    timeMode,
    years,
    months,
    theme,
    waterClassificationUrls,
  ]);

  useEffect(() => {
    if (mapLoaded) {
      updateHydroelectricLayer();
    }
  }, [
    mapLoaded,
    filtersWaterClassification.hydroelectric,
    analysis,
    timeMode,
    years,
    months,
    theme,
    waterClassificationUrls,
  ]);

  useEffect(() => {
    if (mapLoaded) {
      updateMiningLayer();
    }
  }, [
    mapLoaded,
    filtersWaterClassification.mining,
    analysis,
    timeMode,
    years,
    months,
    theme,
    waterClassificationUrls,
  ]);

  useEffect(() => {
    if (mapLoaded) {
      updateAquacultureLayer();
    }
  }, [
    mapLoaded,
    filtersWaterClassification.aquaculture,
    analysis,
    timeMode,
    years,
    months,
    theme,
    waterClassificationUrls,
  ]);

  /**
   * Handle the style loading.
   */
  useEffect(() => {
    const map = mapRef.current.getMap();

    function onLoad() {
      updateRasterLayer();
      updateNaturalLayer();
      updateAnthropicLayer();
      updateHydroelectricLayer();
      updateMiningLayer();
    }

    if (mapLoaded) {
      map.on("style.load", onLoad);
    }

    return () => {
      map.off("style.load", onLoad);
    };
  }, [baseMap, analysis, theme]);

  /**
   * This function enter inside a territory without broke the autocomplete logic.
   */
  const enterTerritory = (feature) => {
    if (feature && territoriesOptions) {
      const index = territoriesOptions.findIndex(
        (element) => element.type === grouping
      );

      const newTerritory = {
        code: feature.properties.code,
        name: feature.properties.name,
        type: feature.properties.territoryType,
      };

      if (index >= 0) {
        if (
          !territoriesOptions.find(
            (element) => element.code === feature.properties.code
          )
        ) {
          territoriesOptions.splice(index, 0, newTerritory);
        }
        setTerritoriesOptions(territoriesOptions);
      } else {
        setTerritoriesOptions([newTerritory, ...territoriesOptions]);
      }
      setTerritory(newTerritory);
    }
  };

  /**
   * This function pull the territory without broke the autocomplete logic.
   */
  const pullTerritory = () => {
    if (territoryHistory && territoryHistory.length > 1) {
      const lastTerritory = territoryHistory[territoryHistory.length - 2];

      if (lastTerritory && territoriesOptions) {
        const index = territoriesOptions.findIndex(
          (element) => element.type === grouping
        );
        const newTerritory = { ...lastTerritory };

        if (index >= 0) {
          if (
            !territoriesOptions.find(
              (element) => element.code === lastTerritory.code
            )
          ) {
            territoriesOptions.splice(index, 0, newTerritory);
          }
          setTerritoriesOptions(territoriesOptions);
        } else {
          setTerritoriesOptions([newTerritory, ...territoriesOptions]);
        }

        setTerritory(newTerritory);
      }
    }
  };

  /**
   * Set the map click.
   */
  useEffect(() => {
    setOnClick(() => (event) => {
      if (event) {
        const { features } = event;
        const feature = features && features.find((f) => f.layer.id === "data");

        if (isTouchDevice()) {
          if (feature) {
            setPopup(
              <CustomPopup
                feature={feature}
                lng={event.lngLat[0]}
                lat={event.lngLat[1]}
                closePopup={() => setPopup(null)}
                accessTerritory={() => {
                  enterTerritory(feature);
                  setPopup(null);
                }}
              />
            );
          } else {
            setPopup(null);
            pullTerritory();
          }
        } else if (feature) {
          enterTerritory(feature);

          /* const map = mapRef.current.getMap();

          const [minLng, minLat, maxLng, maxLat] = bbox(feature);

          map.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            { padding: 40, duration: 1000 }
          ); */
        } else {
          pullTerritory();
        }
      }
    });
  }, [grouping]);

  useEffect(() => {
    setOnHover(() => (event) => {
      if (event) {
        const { features } = event;
        const feature = features && features.find((f) => f.layer.id === "data");

        if (feature) {
          if (!isTouchDevice()) {
            setInfoContent(feature);
          }
        } else {
          setInfoContent(null);
        }
      }
    });
  }, [grouping]);

  /**
   * A layer used to make a focus on map.
   */
  useEffect(() => {
    if (mapLoaded) {
      const map = mapRef.current.getMap();
      if (map.getLayer("vector-layer-focus")) {
        map.removeLayer("vector-layer-focus");
      }
      if (map.getSource("vector_focus")) {
        map.removeSource("vector_focus");
      }

      map.addSource("vector_focus", {
        type: "vector",
        tiles: [
          `${process.env.REACT_APP_API_URL}territories/shape/tiles/${territory.type}/${territory.code}/${grouping}/{z}/{x}/{y}.mvt`,
        ],
      });

      let paintProperties = {
        "fill-opacity": 1,
        "fill-color": theme.background.primary,
        "fill-outline-color": "transparent",
      };

      if (transparentBackground) {
        paintProperties = {
          "fill-opacity":
            baseMap === "default"
              ? theme.mode === "light"
                ? 0.8
                : 0.7
              : theme.mode === "light"
              ? 0.4
              : 0.6,
          "fill-color": baseMap === "default" ? theme.map.water : "black",
          "fill-outline-color": "transparent",
        };
      }

      map.addLayer(
        {
          id: "vector-layer-focus",
          source: "vector_focus",
          type: "fill",
          "source-layer": "default",
          minzoom: 0,
          maxzoom: 22,
          paint: paintProperties,
        }
        // "road-label"
      );
    }
  }, [mapLoaded, territory, grouping, theme, baseMap, transparentBackground]);

  /**
   * A layer to create feature lines.
   */
  useEffect(() => {
    if (mapLoaded) {
      const map = mapRef.current.getMap();

      if (map.getLayer("conflict-layer-shapes")) {
        map.removeLayer("conflict-layer-shapes");
      }
      if (map.getSource("conflict_shapes")) {
        map.removeSource("conflict_shapes");
      }
      map.addSource("conflict_shapes", {
        type: "geojson",
        data: conflictsData,
      });
      map.addLayer(
        {
          id: "conflict-layer-shapes",
          source: "conflict_shapes",
          type: "line",
          minzoom: 0,
          maxzoom: 22,
          layout: {
            "line-join": "round",
            "line-cap": "butt",
          },
          paint: {
            "line-color": baseMap === "default" ? theme.map.border : "black",
            "line-opacity":
              baseMap === "default" ? (theme.mode === "light" ? 1 : 0.5) : 1,
            "line-width": baseMap === "default" ? 1 : 1.3,
            "line-dasharray": baseMap === "default" ? [2, 4] : [1, 2],
          },
        },
        map.getLayer("vector-layer-focus") ? "vector-layer-focus" : "road-label"
      );

      if (map.getLayer("vector-layer-shapes")) {
        map.removeLayer("vector-layer-shapes");
      }
      if (map.getSource("vector_shapes")) {
        map.removeSource("vector_shapes");
      }

      map.addSource("vector_shapes", {
        type: "vector",
        tiles: [
          `${process.env.REACT_APP_API_URL}territories/grouping/tiles/${territory.type}/${territory.code}/${grouping}/{z}/{x}/{y}.mvt`,
        ],
      });
      map.addLayer(
        {
          id: "vector-layer-shapes",
          source: "vector_shapes",
          type: "line",
          "source-layer": "default",
          minzoom: 0,
          maxzoom: 22,
          layout: {
            "line-join": "round",
            "line-cap": "butt",
          },
          paint: {
            "line-color": baseMap === "default" ? theme.map.border : "black",
            "line-opacity":
              baseMap === "default" ? (theme.mode === "light" ? 1 : 0.5) : 1,
            "line-width": baseMap === "default" ? 1 : 1.3,
            "line-dasharray": baseMap === "default" ? [2, 4] : [1, 2],
          },
        },
        "road-label"
      );
    }
  }, [mapLoaded, territory, grouping, theme, baseMap]);

  /**
   * A layer to create features events.
   */
  useEffect(() => {
    if (mapLoaded) {
      const map = mapRef.current.getMap();
      if (map.getLayer("data")) {
        map.removeLayer("data");
      }
      if (map.getSource("features")) {
        map.removeSource("features");
      }

      map.addSource("features", {
        type: "vector",
        tiles: [
          `${process.env.REACT_APP_API_URL}territories/grouping/tiles/${territory.type}/${territory.code}/${grouping}/{z}/{x}/{y}.mvt`,
        ],
      });
      map.addLayer({
        id: "data",
        source: "features",
        type: "fill",
        paint: {
          "fill-opacity": 0,
        },
        "source-layer": "default",
      });
    }
  }, [mapLoaded, territory, grouping]);

  /*
    First load
  */
  useEffect(() => {
    let isSubscribed = true;

    if (mapLoaded) {
      api
        .get(`/territories/bounds/${territory.type}/${territory.code}`, {
          params: { initiative },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            const [minLng, minLat, maxLng, maxLat] = bbox(data);
            const vp = new WebMercatorViewport(viewport);

            const { longitude, latitude, zoom } = vp.fitBounds(
              [
                [minLng, minLat],
                [maxLng, maxLat],
              ],
              {
                padding: {
                  top: 100,
                  bottom: 130,
                  left: !isTouchDevice() && statisticsOpened ? 0 : 100,
                  right: !isTouchDevice() && statisticsOpened ? 250 : 100,
                },
                duration: 1000,
              }
            );

            if (isSubscribed && !animation) {
              setViewport({
                ...viewport,
                longitude,
                latitude,
                zoom,
                transitionInterpolator: new FlyToInterpolator(),
                transitionDuration: 300,
                transitionEasing: easeCubic,
              });
            }
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [mapLoaded, territory, initiative]);

  /**
   * Fetch intermittent raster url.
   */
  useEffect(() => {
    let isSubscribed = true;

    const startYear = years[0];
    let endYear = years[0];

    if (years.length > 1) {
      endYear = years[1];
    }
    if (analysis === analysisOptions.surface?.key) {
      api
        .get(`/surface/intermittent/map/${startYear}/${endYear}`, {
          params: { initiative },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setFetchedIntermittentRaster(data.url);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [analysis, years, initiative]);

  /**
   * Fetch irrigation url.
   */
  useEffect(() => {
    let isSubscribed = true;

    let endYear = years[0];

    if (years.length > 1) {
      endYear = years[1];
    }

    if (analysis === analysisOptions.surface?.key) {
      api
        .get(`/surface/irrigation/map/${endYear}`, { params: { initiative } })
        .then(({ data }) => {
          if (isSubscribed) {
            setFetchedIrrigationRaster(data.url);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [analysis, years, initiative]);

  useEffect(() => {
    const map = mapRef?.current?.getMap();

    function addLayer() {
      if (map?.getLayer(`intermittent-layer`)) {
        map?.removeLayer(`intermittent-layer`);
      }
      if (map?.getSource(`intermittent-source`)) {
        map?.removeSource(`intermittent-source`);
      }

      if (fetchedIntermittentRaster && layers.intermittentWater.enabled) {
        map?.addSource(`intermittent-source`, {
          type: "raster",
          tiles: [fetchedIntermittentRaster],
          tileSize: 256,
        });

        map?.addLayer(
          {
            id: `intermittent-layer`,
            source: `intermittent-source`,
            type: "raster",
            paint: {
              "raster-opacity": opacity / 100,
            },
          },
          "road-street"
        );
      }
    }

    if (map) {
      addLayer();
      map.on("style.load", addLayer);
    }

    return () => {
      if (map) {
        map.off("style.load", addLayer);
      }
    };
  }, [fetchedIntermittentRaster, opacity, layers.intermittentWater]);

  useEffect(() => {
    const map = mapRef?.current?.getMap();

    function addLayer() {
      if (map?.getLayer(`irrigation-layer`)) {
        map?.removeLayer(`irrigation-layer`);
      }
      if (map?.getSource(`irrigation-source`)) {
        map?.removeSource(`irrigation-source`);
      }

      if (fetchedIrrigationRaster && layers.irrigation.enabled) {
        map?.addSource(`irrigation-source`, {
          type: "raster",
          tiles: [fetchedIrrigationRaster],
          tileSize: 256,
        });

        map?.addLayer(
          {
            id: `irrigation-layer`,
            source: `irrigation-source`,
            type: "raster",
            paint: {
              "raster-opacity": opacity / 100,
            },
          },
          "road-street"
        );
      }
    }

    if (map) {
      addLayer();
      map.on("style.load", addLayer);
    }

    return () => {
      if (map) {
        map.off("style.load", addLayer);
      }
    };
  }, [fetchedIrrigationRaster, opacity, layers.irrigation]);

  useEffect(() => {
    let isSubscribed = true;
    const map = mapRef?.current?.getMap();

    async function fetchItems() {
      const items = {};
      const promisesToAwait = [];

      if (fetchedInititiveTerritoriesRaster) {
        Object.keys(fetchedInititiveTerritoriesRaster).forEach((key) => {
          if (map?.getLayer(`initiative-territory-layer-${key}`)) {
            map?.removeLayer(`initiative-territory-layer-${key}`);
          }
          if (map?.getSource(`initiative-territory-source-${key}`)) {
            map?.removeSource(`initiative-territory-source-${key}`);
          }
        });
      }

      Object.keys(layers.initiative).forEach((key) => {
        if (layers.initiative[key].enabled) {
          promisesToAwait.push(
            api
              .get(`/territories/${key}/map`, {
                params: {
                  color: theme.map.selection.stroke,
                },
              })
              .then(({ data }) => {
                items[key] = data;
              })
          );
        }
      });

      await Promise.all(promisesToAwait);
      if (isSubscribed) setFetchedInititiveTerritoriesRaster(items);
    }

    if (layers.initiative) {
      fetchItems();
    }

    return () => {
      isSubscribed = false;
    };
  }, [layers.initiative, theme]);

  useEffect(() => {
    const map = mapRef?.current?.getMap();

    function addLayer() {
      if (fetchedInititiveTerritoriesRaster) {
        Object.keys(fetchedInititiveTerritoriesRaster).forEach((key) => {
          if (map?.getLayer(`initiative-territory-layer-${key}`)) {
            map?.removeLayer(`initiative-territory-layer-${key}`);
          }
          if (map?.getSource(`initiative-territory-source-${key}`)) {
            map?.removeSource(`initiative-territory-source-${key}`);
          }

          map?.addSource(`initiative-territory-source-${key}`, {
            type: "raster",
            tiles: [fetchedInititiveTerritoriesRaster[key].url],
            tileSize: 256,
          });

          map?.addLayer(
            {
              id: `initiative-territory-layer-${key}`,
              source: `initiative-territory-source-${key}`,
              type: "raster",
              paint: {
                "raster-opacity": opacity / 100,
              },
            },
            "road-street"
          );
        });
      }
    }

    if (map) {
      addLayer();
      map.on("style.load", addLayer);
    }

    return () => {
      if (map) {
        map.off("style.load", addLayer);
      }
    };
  }, [fetchedInititiveTerritoriesRaster, opacity]);

  return <>{popup}</>;
}

export default Map;
